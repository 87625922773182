import './App.scss';
import { ConfigProvider, Spin } from 'antd';
import { ErrorPage } from '../common/Components/ErrorPage/ErrorPage';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { persistor, store } from './store';
import { useAppSelector } from './hooks';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Footer from '../features/Footer/Footer';
import Login from '../features/Login/Login';
import NavBar from '../features/NavBar/NavBar';
import NetworkSelection from '../features/Login/NetworkSelection';
import RequestsPage from '../features/RequestsPage/RequestsPage';
import ruRU from 'antd/lib/locale/ru_RU';
import { YMInitializer } from 'react-yandex-metrika';

export const ProtectedRoute = ({ children }: any) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const networkId = useAppSelector((state) => state.app.networkId) || '';
  const medOrganizationId = useAppSelector((state) => state.app.medOrganizationId) || '';

  useEffect(() => {
    console.log(`%cVersion: ${window.version}`, 'color: green; font-size: 20px;');
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        navigate('/login');
      } else if (!networkId && !medOrganizationId) {
        navigate('/networkSelection');
      }
    }
  }, [user, networkId, isLoading, navigate]);
  return isLoading ? <Spin size="large" className="pageLoader" /> : children;
};

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <NavBar />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/',
          element: <RequestsPage />,
        },
        {
          path: '*',
          element: (
            <ErrorPage
              title="Упс! Страница не найдена"
              text="Проверьте, нет ли опечатки в адресе, или вернитесь на главную страницу"
            />
          ),
        },
      ],
    },
    {
      path: '/networkSelection',
      index: true,
      element: (
        <ProtectedRoute>
          <NetworkSelection />
        </ProtectedRoute>
      ),
    },
    {
      path: '/login',
      index: true,
      element: <Login />,
    },
  ],
  { basename: `${process.env.PUBLIC_URL}/` },
);

function App() {
  return (
    <>
      <ConfigProvider
        locale={ruRU}
        theme={{
          token: {
            colorPrimary: '#6266a7',
            fontFamily: 'Ubuntu',
            colorText: '#50515B',
          },
          hashed: false,
        }}
      >
        <Provider store={store}>
          <PersistGate loading={<Spin size="large" className="pageLoader" />} persistor={persistor}>
            <YMInitializer
              accounts={[96310615, 96143494]}
              options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true }}
            />
            <div className="appContainer">
              <RouterProvider router={router} />
              <Footer />
            </div>
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </>
  );
}

export default App;
